/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

import { 
  Search,
  OpenWith,
  BrightnessAuto,
  Rotate90DegreesCw,
  BorderVertical,
  InvertColors
} from '@mui/icons-material';

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                NDT Software
              </h2>
              <p className="font-semibold text-3xl text-blueGray-600">
                Your partner in X-ray image processing.
              </p>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                NDT Software is a Europe based company that specializes in developing <span className="whitespace-nowrap">X-ray</span> image processing and archiving solutions.
              </p>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Our experience is in both Healthcare and Non Destructive Testing.
              </p>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/pattern_react.png").default}
          alt="..."
        />
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    Compatibility and high availability
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    Our focus is to provide highly available and durable cloud based solutions.
                  </p>
                  <p className="text-md font-light mt-2 text-white">
                    Compatibility with high-resolution screens as well as with mobile devices is a must have in the world of <span className="whitespace-nowrap">X-ray</span> image interpretation
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-sitemap"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        X-ray compatibility
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Ability to process, convert and display images from every <span className="whitespace-nowrap">X-ray</span> manufacturer.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-drafting-compass"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        X-ray viewer tools
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        HTML5 <span className="whitespace-nowrap">X-ray</span> viewer offers a variety of tools with the possibility to develop custom tools if needed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-newspaper"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Storage, Archiving</h6>
                      <p className="mb-4 text-blueGray-500">
                        Highly available, durable and searchable cloud-based solutions for X-ray images, reports, photos, spreadsheets, etc.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-file-alt"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Embed into your software
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                        Seamless integration of NDT Software solutions into other software and websites to offer 3rd party customers an easy to use X-ray viewer and archiving solution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-sitemap text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                X-ray compatibility
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Every X-ray manufacturer uses a proprietary way of storing their <span className="whitespace-nowrap">X-ray</span> image data. That is why professionals need software from the <span className="whitespace-nowrap">X-ray</span> manufacturer in order to open that specific image file.
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                NDT Software uses custom made convertors for every file format and provides an HTML5 viewer to display and interpret. No additional software is required to open any <span className="whitespace-nowrap">X-ray</span> image.
              </p>
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={require("assets/img/Tooth3.png").default}
                  className="w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/Pipe1.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/Tooth2.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                <img
                  alt="..."
                  src={require("assets/img/Weld3.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/Weld.png").default}
                  className="w-full align-middle rounded absolute shadow-lg max-w-580-px -left-20-px top-210-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/Tooth1.png").default}
                  className="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center pt-32 mt-32">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <div className="justify-center flex flex-wrap relative">
                <div className="my-4 w-full lg:w-6/12 px-4">
                  
                    <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <Search />
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Magnifier
                      </p>
                    </div>
                  
                    <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <BrightnessAuto />
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Auto Contrast/Brightness
                      </p>
                    </div>
                  
                    <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <InvertColors />
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Invert Colors
                      </p>
                    </div>
                  
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                  
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <BorderVertical />
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Vertical / Horizontal Flip
                      </p>
                    </div>
                    <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <OpenWith />
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Pan
                      </p>
                    </div>
                    <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <Rotate90DegreesCw />
                      </div>
                      <p className="text-lg text-white mt-4 font-semibold">
                        Rotate
                      </p>
                    </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-drafting-compass text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                X-ray viewer tools
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                Apart from standard Contrast/Brightness, Zoom In/Out, Pan or Magnifier, NDT Software HTML5 viewer allows users to make annotations,
                arrows or to use automatic Brightness/Contrast by selecting an area of interest.
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                The viewer also provides industry specific tools like Duplex Wire or Signal to Noise Ratio.
              </p>
             
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-file-alt text-xl"></i>
                </div>
                <h3 className="text-3xl font-semibold">
                  Embed into your software
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  NDT Software solutions can be embedded into 3rd party software by design.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  Search, gallery, <span className="whitespace-nowrap">X-ray</span> viewer and tools can be customized to fit the design of any 3rd party software.
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                          <i className="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          High security standards
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                          <i className="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Customizable HTML5 viewer and gallery
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Simple to add new features
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/documentation.png").default}
              />
            </div>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Contact</h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              NDT Software has offices located in the Prague Innovation Center, Czech Republic.
            </p>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              Inovační 122, 252 41 Zlatníky-Hodkovice, Czech Republic
            </p>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              Email: <a href="mailto:info@ndtsoftware.eu">info@ndtsoftware.eu</a>
            </p>
            
          </div>
        </div>
      </section>

      <section className="block relative z-1 bg-blueGray-600">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex justify-center">

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Prague Innovation Center
                  </h5>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/Innocrystal.png").default}
                      />
                    </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-2 bg-blueGray-600 overflow-hidden">
        <div className="container mx-auto pb-64">
          
        </div>
      </section>

      <Footer />
    </>
  );
}
