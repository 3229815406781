/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex items-center justify-between flex-wrap bg-black p-4">
      <Link to="/">
        <div className="flex items-center flex-shrink-0 text-white mr-6 px-4">
            <span className="font-semibold text-2xl tracking-tight">
              NDT
            </span>
            <span className="w-11 h-10 mx-1 px-2 pt-2" >
                <img src="/Logo-icon-transparent.png" alt="logo" className="w-8"/>
            </span>
            <span className="font-semibold text-2xl tracking-tight">
              Software
            </span>
        </div> 
        </Link>
      </nav>
      {/*
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between flex-wrap lg:w-auto lg:static lg:block lg:justify-start">
          <img
                  alt="..."
                  src={require("assets/img/Logo-icon-transparent.png").default}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                <div className="flex items-center flex-shrink-0 mr-6">
                <Link to="/" className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase">
                  NDT
                  <img src="/Logo-icon-transparent.png" alt="logo" className="w-5" />
                  Software
                </Link>
                </div>
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              NDT
              <img src="/Logo-icon-transparent.png" alt="logo" className="w-5" />
              Software
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            
          </div>
        </div>
      </nav>
          */}
    </>
  );
}
